import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import superagent from '../core/request';
import { TABLE_SHOW } from '../lib/events';
import Components from '../core/Components';

export default el => {

    const $el = $(el);

    const $btnOpen = $el.find('.js-table-open');
    const $body = $('body');
    const $btnCloseOverlay = $('.experience-overlay__close');
    const $itemLink = $('.js-item-link');
    const $overlay = $('.experience-overlay');
    const $overlayContent = $('.experience-overlay__wrap');
    const $underlay = $('.experience-wrap');
    const basePath = window.location.pathname;

    let $oldItem = $('#table-item-strategy .experience-table__detail-wrap');
    let $newItem;

    let xhr = null;
    let isAnimating = false;
    let scrollPosition;
    let isFirst = true;
    let isOverlay = false;

    let { size: currentBreakpoint } = Viewport.breakpoint;

    const showOnLoad = () => {
        gsap.set($oldItem.get(0), {
            zIndex: 99,
            height: 'auto'
        });
        $('#table-item-strategy .js-table-open')
            .addClass('js-tableDetailIsOpen')
            .attr('tabIndex', '-1');
        Viewport.enableTabbingOnElement($oldItem.get(0));
        isFirst = false;
    };

    const showOverlay = () => {
        isOverlay = true;
        Viewport.lockTabbing($overlay.get(0), $btnCloseOverlay.get(0));
        $overlay.addClass('isFixed');
        gsap.set($overlay.get(0), { display: 'block' });
        gsap.set($overlayContent.get(0), { opacity: 0 });
        gsap.to($overlay.get(0), {
            opacity: 1,
            duration: 0.25,
            onComplete() {
                $underlay.get(0).hidden = true;
                window.scrollTo(0, 0);;
                $overlay.removeClass('isFixed');
                gsap.to($overlayContent.get(0), {
                    opacity: 1,
                    duration: 0.5,
                    onComplete() {
                        window.sr.init();
                    }
                });
            }
        });
    };

    const getItem = (url, path) => {
        if (xhr) {
            xhr.abort();
        }
        xhr = superagent.get(url);
        xhr
            .then(({
                status,
                text: html
            }) => {
                if (status !== 200 || !html) {
                    throw new Error();
                }
                $overlayContent.html(html);
                Components.init($overlay.get(0));
                showOverlay();
                if (window.history.replaceState) {
                    window.history.replaceState({}, document.title, path);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                xhr = null;
            });
    };

    const hideOverlay = () => {
        $underlay.get(0).hidden = false;
        $overlay.addClass('isFixed');

        window.scrollTo(0, scrollPosition);
        gsap.to($overlay.get(0), {
            opacity: 0,
            duration: 0.75,
            onComplete() {
                Components.destroy($overlay.get(0));
                gsap.set($overlay.get(0), { clearProps: 'all' });
                gsap.set($overlayContent.get(0), { clearProps: 'all' });
                $overlay.removeClass('isFixed');

                Viewport.releaseTabbing();

                $('.js-tableDetailIsOpen').attr('tabIndex', '-1');
                Viewport.enableTabbingOnElement($oldItem.get(0));
                $oldItem.find('.js-item-link').focus();
                isOverlay = false;
            }
        });
        if (window.history.replaceState) {
            window.history.replaceState({}, document.title, basePath);
        }
    };

    const show = target => {
        Dispatch.emit(TABLE_SHOW, target);
    };

    const onKeyUp = e => {
        if (e.keyCode !== 27 || !isOverlay) {
            return;
        }
        hideOverlay();
    };

    const toggleComplete = () => {
        if (!isFirst) {
            gsap.set($oldItem.get(0), { clearProps: 'all' });
        }
        $oldItem = $newItem;
        isAnimating = false;
        if (isFirst) {
            isFirst = false;
        }
    };

    const detailToggle = () => {
        gsap.set($oldItem.get(0), { zIndex: 99 });
        gsap.set($newItem.get(0), {
            zIndex: 98,
            height: 'auto'
        });
        gsap.to($oldItem.get(0), {
            opacity: 0,
            duration: 0.5,
            onComplete: toggleComplete
        });
    };

    const detailToggleMobile = () => {
        if (!isFirst) {
            gsap.to($oldItem.get(0), { height: 0, duration: 0.4 });
        }
        gsap.timeline({
            onComplete: toggleComplete
        })
            .fromTo($newItem.get(0), {
                height: 0
            }, {
                height: 'auto',
                duration: 0.4
            });
    };

    const onShow = (key, data) => {
        $newItem = $(data).find('.experience-table__detail-wrap');
        isAnimating = true;
        Viewport.releaseTabbing();
        $('.js-tableDetailIsOpen').attr('tabIndex', '-1');
        Viewport.enableTabbingOnElement($newItem.get(0));
        if (currentBreakpoint >= 1200) {
            detailToggle();
        } else {
            detailToggleMobile();
        }
    };

    const onBreakpoint = () => {
        const oldBreakpoint = currentBreakpoint;
        currentBreakpoint = Viewport.breakpoint.size;
        if (currentBreakpoint >= 1200 && oldBreakpoint < 1200 && isFirst) {
            showOnLoad();
        }
    };

    const init = () => {
        Dispatch.on(TABLE_SHOW, onShow);
        Viewport.on('breakpoint', onBreakpoint);

        $body.on('keyup', onKeyUp);

        if (currentBreakpoint >= 1200) {
            showOnLoad();
        }

        $btnOpen.on('click', function (e) {
            e.preventDefault();
            if (isAnimating || $(this)
                .hasClass('js-tableDetailIsOpen')) {
                return;
            }
            $('.js-tableDetailIsOpen')
                .removeClass('js-tableDetailIsOpen')
                .attr('tabIndex', null);
            $(this)
                .addClass('js-tableDetailIsOpen')
                .attr('tabIndex', '-1');
            const target = $(this)
                .attr('href');
            show(target);
        });

        $itemLink.on('click', function (e) {
            e.preventDefault();
            getItem($(this)
                .attr('href'), $(this)
                .get(0).pathname);
            scrollPosition = Viewport.scrollTop;
        });

        $btnCloseOverlay.on('click', e => {
            e.preventDefault();
            hideOverlay();
        });

        onBreakpoint();
    };

    const destroy = () => {
        $body.off('keyup', onKeyUp);
        Dispatch.off(TABLE_SHOW, onShow);
        Viewport.off('breakpoint', onBreakpoint);
        $btnOpen.off('click');
        $itemLink.off('click');
        $btnCloseOverlay.off('click');
    };

    return {
        init,
        destroy
    };

};
