import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);

    const $text = $el.find('.team-fact__text');
    const $items = $el.find('.team-fact__box');

    let { size: currentBreakpoint } = Viewport.breakpoint;
    let isBig = false;
    let newHeight = 0;

    const resizeItems = () => {
        gsap.set($items.get(), { height: newHeight });
    };

    const getSizes = () => {
        gsap.set($items.get(), { clearProps: 'height' });
        newHeight = 0;
        $items.each(item => {
            const itemHeight = $(item).height();
            if (itemHeight > newHeight) {
                newHeight = itemHeight;
            }
        });
        resizeItems();
    };

    const checkSizes = () => {
        $text.each(textItem => {
            if ($(textItem).height() > 30) {
                isBig = true;
            }
        });
        if (isBig) {
            getSizes();
            isBig = false;
        } else {
            gsap.set($items.get(), { clearProps: 'height' });
        }
    };

    const onBreakpoint = () => {
        currentBreakpoint = Viewport.breakpoint.size;
        if (currentBreakpoint < 760) {
            gsap.set($items.get(), { clearProps: 'height' });
        }
    };

    const onResize = () => {
        if (currentBreakpoint >= 760) {
            checkSizes();
        }
    };

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
