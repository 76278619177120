import gsap from 'gsap';
import Viewport from './core/Viewport';
import Components from './core/Components';

const ScrollReveal = require('./lib/scrollReveal');
//import ScrollReveal from './lib/scrollReveal';

//import lazySizes from './lib/lazysizes';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();

    window.sr = new ScrollReveal({
        vFactor: 1,
        scale: {direction: 'up', power: '0%'},
        move: '25px',
        easing: 'ease-out',
        over: '0.75s',
    });
    window.sr.init(true);

    Viewport.initTabbing();
    Components.init();
    //lazySizes.init();

};

require('doc-ready')(init);
