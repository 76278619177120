import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { LOADER_COMPLETE } from '../lib/events';

export default el => {

    const $el = $(el);
    const $logo = $el.find('.preloader__logo-wrap');
    const fadeElements = $('[data-sr-wait]');

    let animation;

    const fadeOut = () => {
        gsap.to(el, {
            opacity: 0,
            duration: 1,
            onComplete() {
                el.hidden = true;
                animation.kill();
                gsap.killTweensOf($logo.get(0));
                gsap.killTweensOf(el);
                Dispatch.emit(LOADER_COMPLETE);
                window.sessionStorage.setItem('hasSeenLoader', true);
            }
        });
    };

    const animate = () => {
        gsap.set($logo.get(0), { opacity: 0.05 });
        animation = gsap.to($logo.get(0), {
            opacity: 1,
            ease: 'none',
            duration: 1,
            onComplete() {
                animation.reverse(null, false);
            },
            onReverseComplete: fadeOut
        });
    };

    const fadeInElements = () => {
        fadeElements.each(fadeElement => {
            const dataAttr = fadeElement.getAttribute('data-sr-wait');
            fadeElement.setAttribute('data-sr', dataAttr);
        });
        window.sr.init();
    };

    const init = () => {
        Dispatch.on(LOADER_COMPLETE, fadeInElements, true);
        if (!window.sessionStorage.getItem('hasSeenLoader')) {
            animate();
        } else {
            el.hidden = true;
            Dispatch.emit(LOADER_COMPLETE);
        }
    };

    const destroy = () => {
        Dispatch.off(LOADER_COMPLETE, fadeInElements);
        if (animation) {
            animation.kill();
        }
    };

    return {
        init,
        destroy
    };

};
