import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { MAIN_MENU_CLOSE, MAIN_MENU_OPEN } from '../lib/events';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);

    const $menuItems = $el.find('.menu__item');
    const $menuItemLinks = $el.find('.menu__item a[data-background]');
    const $btnClose = $el.find('.close-menu');
    const $btnCloseTop = $btnClose.find('.top');
    const $btnCloseMiddle = $btnClose.find('.middle');
    const $btnCloseBottom = $btnClose.find('.bottom');
    const $btnOpen = $('.open-menu');
    const $btnOpenRects = $btnOpen.find('path');
    const $body = $('body');
    const $defaultImage = $('.menu__background--default');

    const itemBackgrounds = new WeakMap();
    let isOpen = false;

    const addBackgrounds = () => {
        $el.find('a[data-background]').each(item => {
            const { background: image } = item.dataset;
            const $background = $('<div></div>').addClass('menu__background').css('backgroundImage', `url(${image})`);
            $el.append($background);
            itemBackgrounds.set(item, $background.get(0));
        });
    };

    const preloadImages = (imageArray, index) => {
        const currentIndex = index || 0;
        if (imageArray && imageArray.length > currentIndex) {
            const img = new Image();
            img.onload = () => {
                preloadImages(imageArray, currentIndex + 1);
            };
            img.src = imageArray[currentIndex];
        }
        if (imageArray && imageArray.length === currentIndex) {
            addBackgrounds();
        }
    };

    const hamburgerToClose = () => {
        gsap.timeline()
            .to($btnCloseTop.get(0), {
                y: 9,
                duration: 0.2,
                ease: 'Sine.easeOut'
            }, 'in')
            .to($btnCloseBottom.get(0), {
                y: -9,
                duration: 0.2,
                ease: 'Sine.easeOut'
            }, 'in')
            .set($btnCloseMiddle.get(0), { display: 'none' }, 'out')
            .to($btnCloseTop.get(0), {
                rotation: 45,
                transformOrigin: '50% 50%',
                duration: 0.15,
                ease: 'Sine.easeInOut'
            }, 'out')
            .to($btnCloseBottom.get(0), {
                rotation: -45,
                transformOrigin: '50% 50%',
                duration: 0.15,
                ease: 'Sine.easeInOut'
            }, 'out');
    };

    const closeToHamburger = () => {
        gsap.timeline()
            .to([$btnCloseTop.get(0), $btnCloseBottom.get(0)], {
                rotation: 0,
                transformOrigin: '50% 50%',
                duration: 0.25,
                ease: 'Back.easeIn'
            })
            .set($btnCloseMiddle.get(0), { display: '' })
            .to([$btnCloseTop.get(0), $btnCloseBottom.get(0)], {
                y: 0,
                duration: 0.25,
                ease: 'Back.easeIn'
            });
    };

    const open = () => {
        if (isOpen) {
            return;
        }
        Dispatch.emit(MAIN_MENU_OPEN);
    };

    const close = (tween = true) => {
        if (!isOpen) {
            return;
        }
        Dispatch.emit(MAIN_MENU_CLOSE, { tween });
    };

    const onMenuOpen = () => {
        if (isOpen) {
            return;
        }

        isOpen = true;

        const widthWithoutScrollFix = $el.width();

        gsap.timeline({
            onComplete() {
                $body.css({ overflow: 'hidden' });
                if ($el.width() !== widthWithoutScrollFix) {
                    $btnClose.css({ marginRight: $el.width() - widthWithoutScrollFix });
                }
                hamburgerToClose();
            }
        })
            .set(el, { height: '100%', opacity: 0 })
            .to(el, {
                opacity: 1,
                duration: 0.1,
                ease: 'Sine.easeOut'
            });

        gsap.timeline()
            .set($menuItems.get(), {
                opacity: 0,
                x: 125
            }, 0)
            .to($menuItems.get(), {
                x: 0,
                ease: 'Back.easeOut',
                stagger: 0.03
            }, 'item')
            .to($menuItems.get(), {
                opacity: 1,
                ease: 'Linear.easeOut',
                stagger: 0.03
            }, 'item');

        Viewport.lockTabbing(el, $btnClose.get(0));

    };

    const onMenuClose = (key, { tween = true }) => {

        if (!isOpen) {
            return;
        }

        isOpen = false;

        closeToHamburger();

        $body.css({ overflow: '' });
        $btnClose.css({ marginRight: '' });

        const tl = gsap.timeline({ paused: true })
            .set($menuItems.get(), {
                opacity: 1,
                x: 0
            }, 0)
            .to($menuItems.get(), {
                x: 125,
                ease: 'Back.easeIn',
                duration: 0.2,
                stagger: 0.025
            }, 'item')
            .to($menuItems.get(), {
                opacity: 0,
                ease: 'Linear.easeOut',
                duration: 0.2,
                stagger: 0.025
            }, 'item')
            .to(el, {
                opacity: 0,
                duration: 0.25,
                ease: 'Sine.easeOut'
            })
            .set(el, {
                clearProps: 'all'
            });

        if (tween) {
            tl.play();
        } else {
            tl.pause(tl.totalDuration(), false);
        }

        Viewport.releaseTabbing($btnOpen.get(0));

    };

    const onKeyUp = e => {
        if (e.keyCode === 27) {
            close();
        }
    };

    const init = () => {

        Dispatch.on(MAIN_MENU_OPEN, onMenuOpen);
        Dispatch.on(MAIN_MENU_CLOSE, onMenuClose);

        //Events.subscribe(Events.KEYS.WINDOW.LOADED, onPageLoaded, true);

        $body.on('keyup', onKeyUp);

        $btnOpen.on('click', e => {
            e.preventDefault();
            open();
        });

        $btnOpen.on('mouseenter', e => {
            $btnOpenRects.each((rect, index) => {
                gsap.timeline({
                    delay: 0.05 * index
                })
                    .to(rect, {
                        scaleX: 1.2,
                        transformOrigin: 'right center',
                        duration: 0.2,
                        ease: 'Sine.easeOut'
                    })
                    .to(rect, {
                        scaleX: 1,
                        transformOrigin: 'right center',
                        duration: 0.2,
                        ease: 'Sine.easeOut'
                    });
            });
        });

        $menuItemLinks.on('mouseenter', function () {
            const background = itemBackgrounds.get(this);
            if (!background) {
                return;
            }
            gsap.to(background, {
                opacity: 0.1,
                duration: 0.55,
                ease: 'Sine.easeOut'
            });
            gsap.to($defaultImage.get(0), {
                opacity: 0,
                duration: 0.55,
                ease: 'Sine.easeOut'
            });
        });

        $menuItemLinks.on('mouseleave', function () {
            const background = itemBackgrounds.get(this);
            if (!background) {
                return;
            }
            gsap.to(background, {
                opacity: 0,
                duration: 0.55,
                ease: 'Sine.easeOut'
            });
            gsap.to($defaultImage.get(0), {
                opacity: 0.1,
                duration: 0.55,
                ease: 'Sine.easeOut'
            });
        });

        $btnClose.on('click', e => {
            e.preventDefault();
            close();
        });

        // Preload background images
        const backgroundImages = $el.find('a[data-background]').get().map(item => item.dataset.background);
        preloadImages(backgroundImages);

    };

    const destroy = () => {
        $btnOpen.off('click');
        $btnClose.off('click');
        $menuItemLinks.off('mouseenter mouseleave');
        close(false);
    };

    return {
        init,
        destroy
    };

};
