import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { LOADER_COMPLETE } from '../lib/events';

export default el => {

    const $el = $(el);
    const $heroContainer = $el.parent();
    const $slides = $el.find('.hero-slide');

    let slideIndex = 0;
    let delayId = 0;
    let zIndex = 2;
    let { height: winHeight } = Viewport;

    let delayAndStartHandler;

    const startAnimation = () => {
        if (delayId) {
            clearTimeout(delayId);
            delayId = null;
        }
        slideIndex += 1;
        zIndex += 1;
        if (slideIndex > $slides.get().length - 1) {
            slideIndex = 0;
        }

        const slide = $slides.get(slideIndex);
        gsap.set(slide, { zIndex, opacity: 0 });
        gsap.to(slide, {
            autoAlpha: 1,
            duration: 1,
            ease: 'Sine.easeInOut',
            onComplete: delayAndStartHandler
        });
    };

    const stopAnimation = () => {
        if (delayId) {
            clearTimeout(delayId);
        }
        gsap.killTweensOf($slides.get());
    };

    const delayAndStart = () => {
        if (delayId) {
            clearTimeout(delayId);
        }
        delayId = setTimeout(startAnimation, 3500);
    };

    delayAndStartHandler = delayAndStart;

    const resize = () => {
        gsap.set($heroContainer.get(0), { height: winHeight });
    };

    const onLoaderComplete = () => {
        if ($slides.get().length > 1) {
            delayAndStart();
        }
        if ($heroContainer.height() !== winHeight) {
            resize();
        }
    };

    const onResize = () => {
        const { height: newWinHeight } = Viewport;
        if (newWinHeight !== winHeight) {
            winHeight = newWinHeight;
            resize();
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        Dispatch.on(LOADER_COMPLETE, onLoaderComplete, true);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        Dispatch.off(LOADER_COMPLETE, onLoaderComplete);
        stopAnimation();
    };

    return {
        init,
        destroy
    };

};
