import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const $btn = $(el).find('.hero-link');

    const init = () => {
        $btn.on('click', e => {
            e.preventDefault();
            const $target = $($btn.attr('href')).eq(0);
            if (!$target.length) {
                return;
            }
            gsap.to(window, {
                scrollTo: $target.offset().top,
                duration: 1,
                ease: 'Quart.easeInOut'
            });
        });
    };

    const destroy = () => {
        $btn.off('click');
    };

    return {
        init,
        destroy
    };

};
