/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const MAIN_MENU_OPEN = 'cardo.mainMenuOpen';
export const MAIN_MENU_CLOSE = 'cardo.mainMenuClose';
export const LOADER_SHOW = 'cardo.loaderShow';
export const LOADER_HIDE = 'cardo.loaderHide';
export const FLYOUT_SHOW = 'cardo.flyoutShow';
export const FLYOUT_HIDE = 'cardo.flyoutHide';
export const TABLE_SHOW = 'cardo.tableShow';
export const LOADER_COMPLETE = 'cardo.loaderComplete';
