import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import $ from '../core/Dom';

gsap.registerPlugin(ScrollToPlugin);

export default el => {

    const $btn = $(el)
        .find('.page-footer__button');

    const init = () => {
        $btn.on('click', e => {
            e.preventDefault();
            gsap.to(window, {
                scrollTo: 0,
                duration: 1,
                ease: 'Quart.easeInOut',
                onComplete() {
                    const logo = $('.logo').get(0);
                    if (logo) {
                        logo.focus();
                    }
                }
            });
        });
    };

    const destroy = () => {
        $btn.off('click');
    };

    return {
        init,
        destroy
    };

};
