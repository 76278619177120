import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);

    const hasTransparency = $('body').hasClass('homepage');
    const $offsetElement = $('.js-header-offset');

    const OFFSET_BUFFER = 10;

    let offsetTop = 50;
    let isHidden = false;
    let lastTop = 0;
    let isTransparent = hasTransparency === true;

    const toggleTransparency = transparent => {
        if (!hasTransparency) {
            return;
        }
        if (transparent && !isTransparent) {
            gsap.to(el, {
                color: '#ffffff',
                backgroundColor: 'rgba(255, 255, 255, 0.0001)',
                duration: 0.35,
                ease: 'Sine.easeOut'
            });
        } else if (!transparent && isTransparent) {
            gsap.set(el, {
                color: '#231F20',
                backgroundColor: 'rgba(255, 255, 255, 0.95)'
            });
        }
        isTransparent = transparent;
    };

    const show = () => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        gsap.to(el, {
            yPercent: 0,
            duration: 0.35,
            ease: 'Sine.easeOut'
        });
    };

    const hide = () => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        gsap.to(el, {
            yPercent: -100,
            duration: 0.35,
            ease: 'Sine.easeOut',
            onComplete() {
                toggleTransparency(false);
            }
        });
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (Math.abs(scrollTop - lastTop) > OFFSET_BUFFER) {
            const isScrollingUp = scrollTop < lastTop;
            if (isScrollingUp) {
                toggleTransparency(scrollTop < offsetTop);
                show();
            } else if (scrollTop > offsetTop) {
                hide();
            }
            lastTop = scrollTop;
        }
    };

    const onResize = () => {
        offsetTop = $offsetElement.offset().top - $el.height();
    };

    const init = () => {
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        $el.find('a')
            .on('focus', show);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        $el.find('a')
            .off('focus', show);
    };

    return {
        init,
        destroy
    };

};
