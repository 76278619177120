import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props) => {

    const $el = $(el);

    let $vidElement;
    let $imgElement;
    let videoIsLoaded = false;
    let imageIsloaded = false;
    let { size: currentBreakpoint } = Viewport.breakpoint;

    const insertVideo = () => {
        console.log('insert video', props);
        $vidElement = $('<video class="hero-slide__video" autoplay loop muted></video>');
        $el.append($vidElement);
        if (props.ogg) {
            $vidElement.append(`<source src="${props.ogg}" type="video/ogg">`);
        }
        if (props.mp4) {
            $vidElement.append(`<source src="${props.mp4}" type="video/mp4">`);
        }
        videoIsLoaded = true;
    };

    const insertFallbackImage = () => {
        if (!props.fallback || !props.fallback.length) {
            return;
        }
        $imgElement = $(`<img sizes="100vw" class="hero-slide__image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=" srcset="${props.fallback[0]} 400w, ${props.fallback[1]} 800w, ${props.fallback[2]} 1200w">`);
        $el.append($imgElement);
        imageIsloaded = true;
    };

    const onBreakpoint = () => {
        const { size: newBreakpoint } = Viewport.breakpoint;
        if (!videoIsLoaded && currentBreakpoint < 760 && newBreakpoint >= 760) {
            insertVideo();
            if (imageIsloaded) {
                $imgElement.get(0).hidden = true;
            }
        }
        currentBreakpoint = newBreakpoint;
    };

    const init = () => {
        if (currentBreakpoint >= 760) {
            insertVideo();
        } else {
            insertFallbackImage();
        }
        Viewport.on('breakpoint', onBreakpoint);
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
    };

    return {
        init,
        destroy
    };

};
