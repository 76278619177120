import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { FLYOUT_HIDE, FLYOUT_SHOW } from '../lib/events';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);
    const $btnClose = $el.find('.close-flyout');
    const $btnOpen = $('.open-flyout');
    const $body = $('body');

    let isOpen = false;

    const show = () => {
        Dispatch.emit(FLYOUT_SHOW);
    };

    const hide = () => {
        Dispatch.emit(FLYOUT_HIDE);
    };

    const onKeyUp = e => {
        if (e.keyCode === 27) {
            hide();
        }
    };

    const onShow = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        gsap.to(el, {
            x: '0%',
            duration: 0.7,
            ease: 'Power3.easeInOut'
        });
        $btnOpen.addClass('flyoutIsOpen');
        Viewport.enableTabbingOnElement($el.find('div').get(0));
    };

    const onHide = () => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        gsap.to(el, {
            x: '100%',
            duration: 0.7,
            ease: 'Power3.easeInOut',
            onComplete() {
                $btnOpen.removeClass('flyoutIsOpen');
            }
        });
        Viewport.disableTabbingOnElement($el.find('div').get(0));
    };

    const init = () => {

        Dispatch.on(FLYOUT_SHOW, onShow);
        Dispatch.on(FLYOUT_HIDE, onHide);

        $body.on('keyup', onKeyUp);

        $btnOpen.on('click', e => {
            e.preventDefault();
            show();
        });

        $btnClose.on('click', e => {
            e.preventDefault();
            hide();
        });
    };

    const destroy = () => {
        Dispatch.off(FLYOUT_SHOW, onShow);
        Dispatch.off(FLYOUT_HIDE, onHide);

        $body.off('keyup', onKeyUp);

        $btnOpen.off('click');
        $btnClose.off('click');
    };

    return {
        init,
        destroy
    };

};
