import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const $el = $(el);

    const $slides = $el.find('.content-slide');

    let slideIndex = 0;
    let delayId = 0;
    let zIndex = 2;

    let delayAndStartHandler;

    function startAnimation() {
        slideIndex += 1;
        zIndex += 1;

        if (slideIndex > $slides.get().length - 1) {
            slideIndex = 0;
        }

        const slide = $slides.get(slideIndex);
        gsap.set(slide, {
            zIndex,
            opacity: 0
        });
        gsap.to(slide, {
            autoAlpha: 1,
            duration: 1,
            ease: 'Sine.easeInOut',
            onComplete: delayAndStartHandler
        });
    }

    const stopAnimation = () => {
        if (delayId) {
            clearTimeout(delayId);
        }
        gsap.killTweensOf($slides.get());
    };

    const delayAndStart = () => {
        delayId = setTimeout(startAnimation, 3500);
    };

    delayAndStartHandler = delayAndStart;

    const init = () => {
        delayAndStart();
    };

    const destroy = () => {
        stopAnimation();
    };

    return {
        init,
        destroy
    };

};
